const pricing_tabs = document.querySelectorAll('.pricing_tab');
const pricing_tabContents = document.querySelectorAll('.pricing_tab-content-item');

pricing_tabs.forEach(pricing_tab => {
  pricing_tab.addEventListener('click', function() {
    // remove active class from all pricing_tabs and pricing_tab content
    pricing_tabs.forEach(pricing_tab => pricing_tab.classList.remove('active'));
    pricing_tabContents.forEach(content => content.classList.remove('active'));

    // add active class to current pricing_tab and its corresponding pricing_tab content
    this.classList.add('active');
    document.getElementById(this.dataset.pricing_tabContent).classList.add('active');
  });
});