        // key components navigation
        const tabs = document.querySelectorAll('.operations__tab');
    const tabsContainer = document.querySelector('.operations__tab-container');
    const tabsContent = document.querySelectorAll('.operations__content');
    tabsContainer.addEventListener('click', function (e) {
      const clicked = e.target.closest('.operations__tab');

      // Guard clause
      if (!clicked) return;

      // Remove active classes
      tabs.forEach(t => t.classList.remove('operations__tab--active'));
      tabsContent.forEach(c => c.classList.remove('operations__content--active'));

      // Activate tab
      clicked.classList.add('operations__tab--active');

      // Activate content area
      document
        .querySelector(`.operations__content--${clicked.dataset.tab}`)
        .classList.add('operations__content--active');
    });


    // Working Process JS
    jQuery('.mad-rectangle').click(function () {
        var img_src = jQuery(this).attr('mis-hove-img');
        jQuery('#feature-main-image').attr("src", img_src);
      });
      jQuery('.tab-image').click(function () {
        var largeimage = jQuery(this).attr("large-thumb");
        var activeimage = jQuery(this).attr("active-thumb");
        var testinomial_video = jQuery(this).attr("testinomial-video");
        jQuery(this).attr("src", activeimage);
        jQuery('#Myimage1').attr("src", largeimage);
        jQuery('#testinomiallink').attr("href", testinomial_video);
      });
      jQuery('.tab-image').on("touch", function (event) {
        var largeimage = jQuery(this).attr("large-thumb");
        var activeimage = jQuery(this).attr("active-thumb");
        var testinomial_video = jQuery(this).attr("testinomial-video");
        jQuery(this).attr("src", activeimage);
        jQuery('#Myimage1').attr("src", largeimage);
        jQuery('#testinomiallink').attr("href", testinomial_video);
      });
  
      function imagechange() {
        var image = document.getElementById('Myimage1');
        image.src = "";
      }
  
      function imagechange1() {
        var image = document.getElementById('Myimage1');
        image.src = "";
      }